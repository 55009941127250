<template>
  <v-container>
    <v-row>
      <v-col>
        <h1>Profile</h1>
      </v-col>
    </v-row>
    <v-row class="justify-lg-space-between">
      <v-col cols="12" md="4">
        <h3>Profile Information</h3>
        <p>Update your account's profile information and email address</p>
      </v-col>
      <v-col cols="12" md="6">
        <v-card>
          <v-card-text>
            <v-form>
              <v-text-field v-model="name" label="Name" filled />
              <v-text-field v-model="email" label="Email" type="email" filled />
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="primary" @click="saveProfileInformation">save</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-divider class="my-6" />
    <v-row class="justify-lg-space-between">
      <v-col cols="12" md="4">
        <h3>Update Password</h3>
        <p>Ensure your account is using a long, random password to stay secure.</p>
        <ul>
          <li>
            minimum 12 characters
            <v-icon v-if="newPassword !== null && newPassword.length < 12" small color="red">fas fa-times</v-icon>
            <v-icon v-if="newPassword !== null && newPassword.length >= 12" small color="green">fas fa-check</v-icon>
          </li>
          <li>
            passwords match
            <v-icon v-if="newPassword !== null && newPassword !== confirmPassword" small color="red">fas fa-times</v-icon>
            <v-icon v-if="newPassword !== null && newPassword === confirmPassword" small color="green">fas fa-check</v-icon>
          </li>
        </ul>
      </v-col>
      <v-col cols="12" md="6">
        <v-card>
          <v-card-text>
            <v-form>
              <v-text-field v-model="currentPassword" type="password" label="Current Password" filled />
              <v-text-field v-model="newPassword" type="password" label="New Password" filled counter />
              <v-text-field v-model="confirmPassword" type="password" label="Confirm Password" filled counter />
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="primary" @click="updatePassword">save</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import event, { Events } from '../../event'
export default {
  name: 'Profile',
  data: () => ({
    name: null,
    email: null,
    currentPassword: null,
    newPassword: null,
    confirmPassword: null
  }),
  mounted () {
    this.name = this.$store.state.User.name
    this.email = this.$store.state.User.email
  },
  methods: {
    async saveProfileInformation () {
      const response = await window.axios.put('/v1/user', {
        id: this.$store.state.User.id,
        name: this.name,
        email: this.email
      })

      // update the user store with the updated user information
      this.$store.commit('User/updateUser', response.data.payload)

      // emit the success event
      event.emit(Events.SUCCESS, 'Profile updated successfully')
    },
    async updatePassword () {
      if (this.newPassword !== this.confirmPassword) {
        event.emit(Events.ERROR, 'new password and confirm password do not match.')
        return
      }

      await window.axios.put('/v1/user/password', {
        currentPassword: this.currentPassword,
        newPassword: this.newPassword
      })

      this.currentPassword = null
      this.newPassword = null
      this.confirmPassword = null

      event.emit(Events.SUCCESS, 'Password updated successfully')
    }
  }
}
</script>
